import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';

import dedicationTypes from '../../../../assets/values/dedicationTypes.json';
import { ILegacyURLData } from '../../../models/LegacyURLData';
import { setURLData } from '../../../store/legacy/actions';
import { setExistingPledgeID } from '../../../store/pledge/actions';
import { LegacyKeys, MAIL_CODE, SOLICIT_YEAR } from '../../../util/URLKeys';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setURLData,
  setExistingPledgeID,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export type IProps = ReduxProps & {
  children: React.ReactNode;
};

const LegacyURLUnwrapperView = (props: IProps) => {
  const { setURLData, setExistingPledgeID } = props;
  const searchString = useLocation().search;

  useEffect(() => {
    const queryParams = new URLSearchParams(searchString);
    const urlData = unwrapDataFromURLParams(queryParams);

    setURLData(urlData);
    setExistingPledgeID(urlData?.pledgeid);
  }, [searchString, setURLData, setExistingPledgeID]);

  return <>{props.children}</>;
};

const parseDedicationType = (str?: string) => {
  if (!str) {
    return undefined;
  }

  let typeValue;
  const types = [dedicationTypes.honorary, dedicationTypes.memorial];
  types.forEach(type => {
    if (str.toLowerCase() === type.value.toLowerCase()) {
      typeValue = type.value;
    }
  });

  return typeValue;
};

export const unwrapDataFromURLParams = (params: URLSearchParams) => {
  const unwrap = (key: string) => params.get(key) || undefined;

  try {
    const menu = unwrap(LegacyKeys.MENU)
      ? unwrap(LegacyKeys.MENU)
      : unwrap(LegacyKeys.MENU1);

    const data = {
      menu,
      amount: unwrap(LegacyKeys.AMOUNT) || '100',
      eid: unwrap(LegacyKeys.EID),
      frequency: unwrap(LegacyKeys.FREQUENCY) || 'onetime',
      duration: isNaN(Number(unwrap(LegacyKeys.DURATION)))
        ? undefined
        : Number(unwrap(LegacyKeys.DURATION)),
      recog: parseDedicationType(unwrap(LegacyKeys.RECOG)),
      recogname: unwrap(LegacyKeys.RECOG_NAME),
      comments: unwrap(LegacyKeys.COMMENTS),
      pledgeid: unwrap(LegacyKeys.PLEDGE_ID),

      codings: {
        [MAIL_CODE]: unwrap(MAIL_CODE),
        [SOLICIT_YEAR]: unwrap(SOLICIT_YEAR),
        [LegacyKeys.CODE_SOLICIT]: unwrap(LegacyKeys.CODE_SOLICIT),
        [LegacyKeys.CODE_PROGRAM]: unwrap(LegacyKeys.CODE_PROGRAM),
        [LegacyKeys.CODE_SRCCSU]: unwrap(LegacyKeys.CODE_SRCCSU),
        [LegacyKeys.CODE_SRCSUB]: unwrap(LegacyKeys.CODE_SRCSUB),
        [LegacyKeys.CODE_SOURCE]: unwrap(LegacyKeys.CODE_SOURCE),
        [LegacyKeys.CODE_ENDOWMENT]: unwrap(LegacyKeys.CODE_ENDOWMENT),
        [LegacyKeys.CODE_RESPONSE]: unwrap(LegacyKeys.CODE_RESPONSE),
      },
    };

    return data as ILegacyURLData;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export default connector(LegacyURLUnwrapperView);
