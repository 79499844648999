export const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// This comes from emailregex.com
// eslint gets overeager about some "unnecessary escape characters" here, but I'd rather not try to modify this due to its complexity, so we'll tell eslint to ignore this instead

export const minLengthRegex = new RegExp(`^.{2,}$`);

export const errorForCurrencyInput = (amount?: number) => {
  if (!amount || isNaN(amount) || amount <= 0) {
    return 'Enter a valid dollar amount.';
  }

  if (amount < 1) {
    return 'Enter a value of at least $1.';
  }
};
